<template>
    <div class="w-100 h-100">
        <loader v-if="isStaffEnrollmentLoading"/>
        <div class="intermediar-page">
            <div>
                <img :src="imageSrc" alt="image"/>
            </div>
            <div class="intermediar-page-title">{{ titleComputed }}</div>
            <i18n
                v-if="!isStaffEnrollmentLoading"
                path="PROFILE.MESSAGES.STAFF_ENROLLMENT_REDIRECT_TEXT"
                tag="p"
                class="intermediar-page-text"
            >
              <b-link @click="redirectTo" class="font-weight-bold">{{
                $t('GENERAL.CLICK_HERE') }}
              </b-link>
            </i18n>
        </div>
    </div>
</template>

<script>
import { mapActions } from 'vuex';
import { ENROLL_STAFF_REDIRECT, ENROLL_STAFF_STATUS, UPDATE_STAFF_ENROLLED } from '@/modules/auth/auth-store';

export default {
    name: 'StaffEnrollmentRedirect',
    data() {
        return {
            imageSrc: `${process.env.BASE_URL}media/st-application-registred.png`,
            isStaffEnrollmentLoading: false,
            pooling: null,
            timeoutId: null,
        }
    },
    computed: {
        titleComputed() {
            // TODO: Handle when enrollment status is failed
            if (this.isStaffEnrollmentLoading) {
                return this.$t('PROFILE.MESSAGES.STAFF_ENROLLMENT_PROCESSING_TITLE');
            }
            return this.$t('PROFILE.MESSAGES.STAFF_ENROLLMENT_SUCCESS_TITLE');
        }
    },
    methods: {
        ...mapActions({
            enrollStaffRedirect: `auth/${ENROLL_STAFF_REDIRECT}`,
            enrollStaffStatus: `auth/${ENROLL_STAFF_STATUS}`,
            updateUserAsEnrolled: `auth/${UPDATE_STAFF_ENROLLED}`,
        }),
        async processStaffEnrollment() {
            try {
                const response = await this.enrollStaffStatus({
                    id: this.$route.params.id
                });

                if (response.success) {
                    clearInterval(this.pooling);
                    this.isStaffEnrollmentLoading = false;
                    this.updateUserAsEnrolled();
                    this.timeoutId = setTimeout(() => {
                        this.redirectTo();
                    }, 5000);
                }

                // TODO: Handle if response failed
            } catch {
                clearInterval(this.pooling);
            }
        },
        redirectTo() {
            this.$router.push({ name: 'profile' });
        }
    },
    async created() {
        this.isStaffEnrollmentLoading = true;
        this.enrollStaffRedirect({
            ...this.$route.params
        }).then(() => {
            this.pooling = setInterval(async() => {
                await this.processStaffEnrollment();
            }, 5000);
        }).catch(() => {
            this.isStaffEnrollmentLoading = false;
        });
    },
    beforeDestroy() {
        if (this.pooling) {
            clearInterval(this.pooling);
        }
        if (this.timeoutId) {
            clearTimeout(this.timeoutId);
        }
        this.pooling = null;
        this.timeoutId = null;
    }
}
</script>
<style lang="scss" scoped>
.intermediar-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    button {
        padding-left: 3rem;
        padding-right: 3rem;
    }
}
.intermediar-page-title {
    font-size: 2rem;
    font-weight: 500;
}

.intermediar-page-text {
    text-align: center;
}
</style>
